<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-card class="shiftWarp">
      <!-- parttimer-display-start-->

      <v-row>
        <!-- divisions-start -->
        <v-col cols="4">
          <div class="lblText departmentText">
            <h3>{{ $t('table.headers.job') }}</h3>
          </div>
          <div class="align-self-center mr-9">
            <v-select
              v-model="job"
              :items="listJob"
              item-text="name"
              item-value="id"
              name="pa-1"
              outlined
              :no-data-text="$t('table.messages.no_data')"
            />
          </div>
        </v-col>
        <!-- date-start -->
        <v-col cols="6">
          <div class="lblText dateRadio">
            <h3>{{ $t('messages.date') }}</h3>
            <div>
              <v-radio-group
                v-model="radios"
                mandatory
                row
              >
                <v-radio
                  :label="$t('messages.oneDay')"
                  :value="true"
                />
                <v-radio
                  :label="$t('messages.severalDays')"
                  :value="false"
                />
              </v-radio-group>
            </div>
          </div>
          <div
            v-if="!radios"
            class="align-self-center mr-9 dateWarp"
          >
            <div class="dateStarWrap">
              <v-menu
                v-model="showPickerStartAt"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedStartDateFormatted"
                    name="pa-1"
                    single-line
                    outlined
                    v-bind="attrs"
                    clearable
                    append-icon="mdi-calendar"
                    readonly
                    hint="YYYY/MM/DD"
                    v-on="on"
                    @click:clear="dateStart = ''"
                  />
                </template>
                <v-date-picker
                  v-model="dateStart"
                  :first-day-of-week="0"
                  locale="ja-jn"
                  @input="showPickerStartAt = false"
                />
              </v-menu>
            </div>
            <div class="icon-fall">
              ~
            </div>
            <div>
              <v-menu
                v-model="showPickerEndAt"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedEndDateFormatted"
                    name="pa-1"
                    outlined
                    v-bind="attrs"
                    clearable
                    append-icon="mdi-calendar"
                    readonly
                    hint="YYYY/MM/DD"
                    v-on="on"
                    @click:clear="dateEnd = ''"
                  />
                </template>
                <v-date-picker
                  v-model="dateEnd"
                  :min="dateStart"
                  :first-day-of-week="0"
                  locale="ja-jn"
                  @input="showPickerEndAt = false"
                />
              </v-menu>
            </div>
          </div>
          <div
            v-else
            class="align-self-center mr-9"
          >
            <div>
              <v-menu
                v-model="showPickerStartAt"
                :close-on-content-click="false"
                :nudge-right="200"
                :nudge-bottom="100"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedStartDateFormatted"
                    name="pa-1"
                    single-line
                    outlined
                    v-bind="attrs"
                    clearable
                    append-icon="mdi-calendar"
                    readonly
                    hint="YYYY/MM/DD"
                    v-on="on"
                    @click:clear="dateStart = ''"
                  />
                </template>
                <v-date-picker
                  v-model="dateStart"
                  :first-day-of-week="0"
                  locale="ja-jn"
                  @input="showPickerStartAt = false"
                />
              </v-menu>
            </div>
          </div>
        </v-col>
      </v-row>
      <!--update card-->
      <v-row>
        <v-col>
          <v-card color="#C5DCFA47">
            <v-card-title
              class="text-h4 font-weight-bold accent--text"
              primary-title
            >
              <v-row>
                <v-col
                  class="d-flex align-center pt-0 pb-0"
                  cols="2"
                >
                  {{ $t('messages.bulkUpdateInput') }}
                </v-col>

                <v-col
                  class="pt-0 pb-0 "
                  cols="7"
                >
                  <v-row>
                    <v-col
                      class="d-flex align-center pt-0 pb-0"
                      cols="2"
                    >
                      <v-checkbox
                        v-model="updateMode"
                        :label="$t('messages.bulkAll')"
                        value="ALL_ITEMS"
                      />
                    </v-col>

                    <v-col
                      class="d-flex align-center pt-0 pb-0"
                      cols="4"
                    >
                      <v-checkbox
                        v-model="updateMode"
                        :label="$t('messages.bulkOnlyBlank')"
                        value="ONLY_BLANK_ITEMS"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <validation-observer v-slot="{ handleSubmit }">
                <v-form
                  ref="form"
                  lazy-validation
                  @submit.prevent="handleSubmit(onUpdate)"
                >
                  <v-row>
                    <v-col cols="2">
                      <input-date-picker-filter
                        :value="bulkModel.actualStartAt"
                        prop="actualStartAt"
                        :item="bulkModel"
                        @save="saveFilter"
                        @erorrValidate="showErorr"
                      />
                    </v-col>
                    <v-col cols="2">
                      <input-date-picker-filter
                        :value="bulkModel.actualEndAt"
                        prop="actualEndAt"
                        :item="bulkModel"
                        @save="saveFilter"
                        @erorrValidate="showErorr"
                      />
                    </v-col>
                    <v-col cols="1">
                      <input-date-picker-filter
                        :value="bulkModel.breaktime"
                        prop="breaktime"
                        :item="bulkModel"
                        @save="saveFilter"
                        @erorrValidate="showErorr"
                      />
                    </v-col>
                    <v-col
                      cols="1"
                      class="d-flex align-center justify-end"
                    >
                      <v-btn
                        normal
                        block
                        type="submit"
                        color="accent
                    "
                      >
                        {{ $t('messages.update') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-row>
                      <v-col
                        offset="10"
                        cols="2"
                        class="d-flex justify-end"
                      />
                    </v-row>
                  </v-card-actions>
                </v-form>
              </validation-observer>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-end">
        <v-col cols="2">
          <v-select
            v-model="headers"
            :items="headerOthers"
            :label="$t('messages.selectColumn')"
            multiple
            outlined
            return-object
            :no-data-text="$t('table.messages.no_data')"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 2">
                <span>{{ item.text }}</span>
              </v-chip>
              <span
                v-if="index === 2"
                class="grey--text text-caption"
              >(+{{ headers.length - 2 }} その他)</span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            v-model="selectedItems"
            show-select
            :headers="headers"
            :items="items"
            class="elevation-1 "
            stle
            :loading-text="$t('table.messages.loading')"
            :loading="loading"
            :footer-props="{
              itemsPerPageOptions: [20, 30, 100],
              showFirstLastPage: false,
              'items-per-page-text': $t('table.messages.items_per_page'),
            }"
            :no-data-text="$t('table.messages.no_data')"
            :item-class="itemRowBackground"
          >
            <!-- disale-checkbox-start -->
            <template
              v-slot:item.data-table-select="{ item, isSelected, select }"
            >
              <v-simple-checkbox
                :value="(item.isPresent || item.isPresent === null) ? isSelected : false"
                :disabled="item.isPresent === false"
                @input="select($event)"
              />
            </template>
            <template
              v-slot:footer.page-text
              class="mr-0"
            >
              {{ $t('table.messages.page') }}
              {{ options.page }}
            </template>

            <template v-slot:header.normalWorktime="props">
              <span class="highlights">
                {{ props.header.text }}
              </span>
            </template>

            <template v-slot:header.nightShiftWorktime="props">
              <span class="highlights">
                {{ props.header.text }}
              </span>
            </template>

            <template v-slot:header.otWorktime="props">
              <span class="highlights">
                {{ props.header.text }}
              </span>
            </template>

            <template
              v-if="isOtNightShift"
              v-slot:header.otNightShiftWorktime="props"
            >
              <span class="highlights">
                {{ props.header.text }}
              </span>
            </template>

            <template v-slot:item.index="props">
              {{ props.index + 1 }}
            </template>

            <template v-slot:item.date="props">
              {{ getDate(getValueObject(props.item, 'workdate')) }}
            </template>

            <template v-slot:item.shiftTime="props">
              <span>{{ props.item.shift.shiftStartAt }} ~ {{ props.item.shift.shiftEndAt }}</span>
            </template>

            <template v-slot:item.position="props">
              {{ getValueObject(props.item, 'position') }}
            </template>

            <template v-slot:item.job="props">
              <span class="department">
                {{ getValueObject(props.item, 'shift.job.name') }}
              </span>
            </template>

            <template v-slot:item.shiftInformation="props">
              <span
                class="shiftInformation"
                @click="
                  $router.push({
                    path: '/shift-information-display/' + getValueObject(props.item, 'periodShiftId')
                  })
                "
              >
                {{ getValueObject(props.item, 'periodShiftId') }}
              </span>
            </template>

            <template v-slot:item.attendanceConfirmation="props">
              <span
                class="attendanceConfirmation"
                @click="changeIsPresent(props.item)"
              >
                <span v-if="getValueObject(props.item, 'isPresent') !== false">
                  <i class="mdi mdi-radiobox-blank" />
                </span>
                <span v-else>
                  <i class="attendancConfirm mdi mdi-radiobox-marked" />
                </span>
              </span>
            </template>

            <template v-slot:item.actualStartAt="props">
              <cell-edit-time
                v-if="getValueObject(props.item, 'isPresent') !== false"
                :value="getValueObject(props.item, 'actualStartAt')"
                :check-break-time="getValueObject(props.item, 'checkBreakTime', true)"
                prop="actualStartAt"
                :item="props.item"
                @save="save"
                @erorrValidate="showErorr"
              />
            </template>

            <template v-slot:item.actualEndAt="props">
              <cell-edit-time
                v-if="getValueObject(props.item, 'isPresent') !== false"
                :value="getValueObject(props.item, 'actualEndAt')"
                :check-break-time="getValueObject(props.item, 'checkBreakTime', true)"
                prop="actualEndAt"
                :item="props.item"
                @save="save"
                @erorrValidate="showErorr"
              />
            </template>

            <template v-slot:item.normalWorktime="props">
              <span
                v-if="getValueObject(props.item, 'isPresent') !== false"
                :class="{'errorBreakTime':!getValueObject(props.item, 'checkBreakTime', true)}"
              >
                {{ convertTime(getValueObject(props.item, 'normalWorktime')) }}
              </span>
            </template>

            <template v-slot:item.nightShiftWorktime="props">
              <span
                v-if="getValueObject(props.item, 'isPresent') !== false"
                :class="{'errorBreakTime':!getValueObject(props.item, 'checkBreakTime', true)}"
              >
                {{ convertTime(getValueObject(props.item, 'nightShiftWorktime')) }}
              </span>
            </template>

            <template v-slot:item.otWorktime="props">
              <span
                v-if="getValueObject(props.item, 'isPresent') !== false"
                :class="{'errorBreakTime':!getValueObject(props.item, 'checkBreakTime', true)}"
              >
                {{ convertTime(getValueObject(props.item, 'otWorktime')) }}
              </span>
            </template>

            <template
              v-if="isOtNightShift"
              v-slot:item.otNightShiftWorktime="props"
            >
              <span
                v-if="getValueObject(props.item, 'isPresent') !== false"
                :class="{'errorBreakTime':!getValueObject(props.item, 'checkBreakTime', true)}"
              >
                {{ convertTime(getValueObject(props.item, 'otNightShiftWorktime', 0)) }}
              </span>
            </template>

            <template v-slot:item.breaktime="props">
              <cell-edit-time
                v-if="getValueObject(props.item, 'isPresent') !== false"
                :value="getValueObject(props.item, 'breaktime')"
                :check-break-time="getValueObject(props.item, 'checkBreakTime', true)"
                prop="breaktime"
                :item="props.item"
                @save="save"
                @erorrValidate="showErorr"
              />
            </template>

            <template v-slot:item.totalWorkHour="props">
              <span
                v-if="getValueObject(props.item, 'isPresent') !== false"
                :class="{'errorBreakTime':!getValueObject(props.item, 'checkBreakTime', true)}"
              >
                {{ convertTime(getValueObject(props.item, 'totalWorkHour')) }}
              </span>
            </template>

            <template v-slot:item.note="props">
              <v-edit-dialog>
                <span
                  v-if="getValueObject(props.item, 'isPresent') !== false"
                  class="font-weight-bold textName"
                >
                  {{ props.item.note }}
                </span>
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.note"
                    single-line
                    counter
                    @keydown.enter.prevent="saveNote(props.item)"
                    @blur="saveNote(props.item)"
                  />
                </template>
              </v-edit-dialog>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-row
      v-if="items.length > 0"
      class="d-flex justify-end mt-3"
    >
      <v-col
        cols="2"
        class="d-flex justify-space-between"
      >
        <v-btn
          large
          block
          color="accent"
          type="submit"
          @click="handleAttendenceRecord"
        >
          <span class="text-h5 pr-3 pl-3"> {{ $t('messages.save') }}</span>
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn
          large
          block
          color="info"
          @click="$router.go(-1)"
        >
          <span class="font-weight-bold text-h5">{{
            $t('messages.cancel')
          }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import { get } from 'vuex-pathify'
  import _ from 'lodash'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
  import CellEdit from '../../components/app/CellEdit'
  import CellEditTime from '../../components/app/CellEditTime'
  import InputDatePickerFilter from '../../components/app/InputDatePickerFiler'

  export default {
    name: 'TimeSheetEmployee',
    components: {
      ValidationProvider,
      ValidationObserver,
      CellEdit,
      CellEditTime,
      InputDatePickerFilter,
    },

    data () {
      return {
        menuTimePickerEndAt: false,
        menuTimePickerStartAt: false,
        isEveryItem: false,
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        bulkModel: {
          actualStartAt: '',
          actualEndAt: '',
          breaktime: '',
        },
        updateMode: '',
        selectedItems: [],
        radios: this.$route.query.isOneDay === 'true',
        dateStart: '',
        dateEnd: '',
        headers: [],
        options: {},
        loading: false,
        items: [],
        headerOthers: [],
        listCustomer: [],
        listJob: [
          {
            id: null,
            name: this.$t('messages.all'),
          },
        ],
        customer: '',
        isOtNightShift: null,
        job: null,
        parttimerManageId: '',
        name: '',
        sex: null,
        controlNumber: '',
        total: 0,
        historyItem: '',
        showPickerStartAt: false,
        showPickerEndAt: false,
        parttimer: {},
      }
    },
    computed: {
      ...get('timesheetEmployee', [
        'list',
        'message',
        'status',
        'error',
        'customers',
        'jobs',
        'parttimeEmployee',
      ]),

      computedStartDateFormatted () {
        moment.locale('ja')
        return this.dateStart
          ? moment(this.dateStart).format('YYYY年 MMMM Do')
          : ''
      },
      computedEndDateFormatted () {
        moment.locale('ja')
        return this.dateEnd ? moment(this.dateEnd).format('YYYY年 MMMM Do') : ''
      },

      customerDetail: get('customer@customerDetail'),
      parttimerDetail: get('timesheetEmployee@parttimeEmployee'),
    },
    watch: {
      customerDetail (value) {
        this.customer = value
        this.isOtNightShift = value.isOtNightShift
        this.getHeaders()
      },
      jobs (value) {
        value.data.jobs.forEach(item => {
          this.listJob.push(item)
        })

        this.job = null
      },
      list (value) {
        this.$set(this, 'items', value.data.attendenceRecords.rows)
        this.$set(this, 'total', value.data.attendenceRecords.count)
        this.items = this.convertTimeStringUTC(this.items)
        this.items.forEach(item => {
          this.updateTimeSheetItem(item)
        })
        this.historyItem = _.cloneDeep(this.items)
      },

      job (value) {
        const payload = {}

        if (this.dateStart) {
          payload.startAt = this.dateStart
        }

        if (!this.radios && this.dateEnd) {
          payload.endAt = this.dateEnd
        }

        if (value && value !== -1) {
          payload.jobId = value
        }

        this.getTimeSheets(payload)
      },
      options: {
        handler () {
          const payload = {}

          if (this.dateStart) {
            payload.startAt = this.dateStart
          }

          if (!this.radios && this.dateEnd) {
            payload.endAt = this.dateEnd
          }

          this.getTimeSheets(payload)
        },
        deep: true,
      },
      customer (value) {
        this.getJobs(value.id)
      },
      status (value) {
        if (value === 'success') {
          this.$refs.form.reset()
          this.showSnackBar('messages.success')
          setTimeout(() => {
            this.items = []
            this.dateStart = ''
            this.dateEnd = ''
          }, 500)
        }
        if (value === 'error') {
          this.showSnackBar('error.bad_input')
        }
        this.loading = value === 'loading'
      },
      error (value) {
      // watch error
      },
      radios (value) {
        const payload = {}
        if (value) {
          payload.startAt = this.dateStart
          this.getTimeSheets(payload)
        } else {
          payload.startAt = this.dateStart
          payload.endAt = this.dateEnd
          if (payload.startAt && payload.endAt) {
            this.getTimeSheets(payload)
          }
        }
      },
      dateStart (value) {
        const payload = {}

        if (value) {
          payload.startAt = value
        }

        if (this.job) {
          payload.jobId = this.job
        }

        if (!this.radios) {
          payload.endAt = this.dateEnd
        }

        this.getTimeSheets(payload)
      },
      dateEnd (value) {
        const payload = {}
        if (this.job) {
          payload.jobId = this.job
        }
        if (this.dateStart) {
          payload.startAt = this.dateStart
        }

        if (value) {
          payload.endAt = value
        }
        this.getTimeSheets(payload)
      },
    },
    mounted () {
      let payload = {}
      this.radios = this.$route.query.isOneDay === 'true'
      this.dateStart = this.$route.query.dateStart
      if (!this.radios) {
        this.dateEnd = this.$route.query.dateEnd
        payload = {
          startAt: this.dateStart,
          endAt: this.dateEnd,
        }
      } else {
        payload = {
          startAt: this.dateStart,
        }
      }
      this.getInitInfo()
      this.getTimeSheets(payload)
      this.getHeaders()
      this.headerOthers = []

      extend('checkTime', {
        validate (value) {
          if (/^[0,1,2]{1}[0-9]{1}:[0,1,2]{1}[0-9]{1}$/g.test(value)) {
            const timeSplited = value.split(':')
            if (timeSplited[0] === 24) return false
            return true
          }
          return false
        },
        message: this.$t('validator.invalidTimeFormat'),
      })
      extend('integer', {
        validate (value) {
          return /^\+?(0|[1-9]|\d+\.\d{0,2}\d*)$/.test(value)
        },
        message: this.$t('validator.must_be_number'),
      })
      extend('checktime', {
        events: ['blur'],
        validate (value) {
          if (/^[0,1,2,3,4]{1}[0-9]{1}:[0-5]{1}[0-9]{1}$/g.test(value)) {
            const timeSplited = value.split(':')
            if (timeSplited[0] === 24) return false
            return true
          }
          return false
        },
        message: this.$t('validator.invalidTimeFormat'),
      })
    },
    methods: {
      showErorr (text) {
        this.showSnackBar(text)
      },
      getHeaders () {
        if (!this.isOtNightShift) {
          this.headersMain = [
            {
              text: this.$t('table.headers.index'),
              align: 'start',
              value: 'index',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '4rem',
            },
            {
              text: this.$t('table.headers.date'),
              align: 'center',
              value: 'date',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.shiftTime'),
              align: 'start',
              value: 'shiftTime',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.job'),
              align: 'start',
              value: 'job',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.position'),
              align: 'start',
              value: 'position',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.shiftInformation'),
              align: 'center',
              value: 'shiftInformation',
              class: 'pr-0 pl-2 border-right font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.attendanceConfirmation'),
              align: 'center',
              value: 'attendanceConfirmation',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.actualStartAt'),
              align: 'start',
              value: 'actualStartAt',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.actualEndAt'),
              align: 'start',
              value: 'actualEndAt',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.normalWorkTime'),
              align: 'start',
              value: 'normalWorktime',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.nightWorkTime'),
              align: 'start',
              value: 'nightShiftWorktime',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.workOvertime'),
              align: 'start',
              value: 'otWorktime',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.breakHour'),
              align: 'start',
              value: 'breaktime',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.workingHour'),
              align: 'start',
              value: 'totalWorkHour',
              class: 'pr-0 pl-2 border-right font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.remarks'),
              align: 'start',
              value: 'note',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
          ]
        } else {
          this.headersMain = [
            {
              text: this.$t('table.headers.index'),
              align: 'start',
              value: 'index',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '4rem',
            },
            {
              text: this.$t('table.headers.date'),
              align: 'center',
              value: 'date',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.shiftTime'),
              align: 'start',
              value: 'shiftTime',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.job'),
              align: 'start',
              value: 'job',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.position'),
              align: 'start',
              value: 'position',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.shiftInformation'),
              align: 'center',
              value: 'shiftInformation',
              class: 'pr-0 pl-2 border-right font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.attendanceConfirmation'),
              align: 'center',
              value: 'attendanceConfirmation',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.actualStartAt'),
              align: 'start',
              value: 'actualStartAt',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.actualEndAt'),
              align: 'start',
              value: 'actualEndAt',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.normalWorkTime'),
              align: 'start',
              value: 'normalWorktime',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.nightWorkTime'),
              align: 'start',
              value: 'nightShiftWorktime',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.workOvertime'),
              align: 'start',
              value: 'otWorktime',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.nightOtWorkTime'),
              align: 'center',
              value: 'otNightShiftWorktime',
              class: 'font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.breakHour'),
              align: 'start',
              value: 'breaktime',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.workingHour'),
              align: 'start',
              value: 'totalWorkHour',
              class: 'pr-0 pl-2 border-right font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.remarks'),
              align: 'start',
              value: 'note',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
          ]
        }
        this.headers = [...this.headersMain]
      },
      checkShiftDay (time) {
        return (time > 5 && time < 22) || (time > 29 && time < 46)
      },
      changeIsPresent (item) {
        if (item.isPresent === null) {
          item.isPresent = false
        } else {
          item.isPresent = !item.isPresent
        }
      },
      updateTimeSheetItem (item) {
        let startTime = item.actualStartAt ? this.converNumber(item.actualStartAt) : '00:00'
        let endTime = item.actualEndAt ? this.converNumber(item.actualEndAt) : '00:00'
        let workTime = 0
        let otTime = 0
        let otNightShift = 0

        workTime = endTime - startTime

        if (parseFloat(startTime) > parseFloat(endTime)) {
          workTime = parseFloat(endTime) + 24 - startTime
        }

        if (parseFloat(startTime) > 24) {
          startTime = parseFloat(startTime) - 24
        }

        if (parseFloat(endTime) > 24) {
          endTime = parseFloat(endTime) - 24
        }

        if (workTime > 8) {
          otTime = workTime - 8
          workTime = 8
        }

        if (this.checkShiftDay(parseFloat(startTime)) && !this.checkShiftDay(parseFloat(startTime) + workTime)) {
          item.normalWorktime = 22 - startTime
          item.nightShiftWorktime = workTime - item.normalWorktime
        }

        if (!this.checkShiftDay(parseFloat(startTime)) && this.checkShiftDay(parseFloat(startTime) + workTime)) {
          if (startTime <= 5) {
            item.nightShiftWorktime = 5 - startTime
            item.normalWorktime = workTime - item.nightShiftWorktime
          }

          if (startTime >= 22) {
            item.nightShiftWorktime = 29 - startTime
            item.normalWorktime = workTime - item.nightShiftWorktime
          }
        }

        if (this.checkShiftDay(parseFloat(startTime)) && this.checkShiftDay(parseFloat(startTime) + workTime)) {
          if (parseFloat(startTime) < 22 && this.converNumber(item.actualEndAt) > 29) {
            item.normalWorktime = 22 - parseFloat(startTime) + parseFloat(this.converNumber(item.actualEndAt)) - 29
            item.nightShiftWorktime = 7
          } else {
            item.normalWorktime = workTime
            item.nightShiftWorktime = 0
          }
        }

        if (!this.checkShiftDay(parseFloat(startTime)) && !this.checkShiftDay(parseFloat(startTime) + workTime)) {
          item.nightShiftWorktime = workTime
          item.normalWorktime = 0
        }

        const worktimeType = this.getValueObject(item, 'shift.worktimeType', null)

        item.checkBreakTime = true

        if (worktimeType) {
          if (item.breaktime > item.normalWorktime) {
            item.checkBreakTime = false
            this.showSnackBar(this.$t('error.breakTime'))
          }
          item.normalWorktime = item.normalWorktime - item.breaktime
        }

        if (worktimeType === false) {
          if (item.breaktime > item.nightShiftWorktime) {
            item.checkBreakTime = false
            this.showSnackBar(this.$t('error.breakTime'))
          }
          item.nightShiftWorktime = item.nightShiftWorktime - item.breaktime
        }
        const breaktime = item.breaktime ? parseFloat(item.breaktime) : 0
        if (otTime > 0 && this.isOtNightShift) {
          const start = parseFloat(this.converNumber(item.actualStartAt))
          const end = parseFloat(this.converNumber(item.actualEndAt))
          if (start < 5 || (start >= 22 && start < 29) || start >= 46) {
            item.normalWorktime += breaktime
          } else if ((start >= 5 && start < 22) || (start >= 29 && start < 46)) {
            if (((start + 8 >= 22 && start + 8 < 29) || (start + 8) >= 46) && (item.nightShiftWorktime + breaktime) < 7) {
              item.nightShiftWorktime += breaktime
            } else {
              item.normalWorktime += breaktime
            }
          }
          const time = start + item.normalWorktime + item.nightShiftWorktime + breaktime
          if (end > 5 && end <= 22) {
            otNightShift = 0
          } else if (end > 22 && end <= 29) {
            if (time <= 22) {
              otNightShift = end - 22
            } else {
              otNightShift = end - time
            }
          } else if (end > 29 && end <= 46) {
            if (time <= 22) {
              otNightShift = end - 22 - (end - 29)
            } else {
              otNightShift = end - time - (end - 29)
            }
          } else if (end > 46) {
            if (time <= 22) {
              otNightShift = end - 22 - (end - 29) + (end - 46)
            } else {
              otNightShift = end - time - (end - 29) + (end - 46)
            }
          }
          otTime -= otNightShift + breaktime
          if (otTime < 0 && !otNightShift) {
            if ((start < 5) || (start >= 22 && start < 29) || (start >= 46)) {
              item.nightShiftWorktime += otTime
              otTime = 0
            } else if ((start >= 5 && start < 22) || (start >= 29 && start < 46)) {
              item.normalWorktime += otTime
              otTime = 0
            }
          } else if (otTime < 0 && otNightShift) {
            if ((start < 5) || (start >= 22 && start < 29) || (start >= 46)) {
              item.nightShiftWorktime += otTime
              otNightShift += otTime
              otTime = 0
            } else if ((start >= 5 && start < 22) || (start >= 29 && start < 46)) {
              if (item.nightShiftWorktime > 0) {
                otNightShift += otTime
                otTime = 0
              } else {
                otNightShift += otTime
                otTime = 0
              }
            }
          } else if (otNightShift < 0) {
            otTime += otNightShift
            otNightShift = 0
          }
        } else if (otTime > 0 && !this.isOtNightShift) {
          const start = parseFloat(this.converNumber(item.actualStartAt))
          if ((start < 5) || (start >= 22 && start < 29) || (start >= 46)) {
            item.normalWorktime += breaktime
          } else if ((start >= 5 && start < 22) || (start >= 29 && start < 46)) {
            if (((start + 8 >= 22 && start + 8 < 29) || (start + 8) >= 46) && (item.nightShiftWorktime + breaktime) < 7) {
              item.nightShiftWorktime += breaktime
            } else {
              item.normalWorktime += breaktime
            }
          }
          otTime -= breaktime
          if (otTime < 0) {
            if ((start < 5) || (start >= 22 && start < 29) || (start >= 46)) {
              item.nightShiftWorktime += otTime
              otTime = 0
            } else if ((start >= 5 && start <= 22) || (start >= 29 && start < 46)) {
              item.normalWorktime += otTime
              otTime = 0
            }
          }
        } else {
          otNightShift = 0
        }
        item.otWorktime = otTime
        item.otNightShiftWorktime = otNightShift
        item.totalWorkHour = parseFloat(item.normalWorktime) + parseFloat(item.otWorktime) + parseFloat(item.nightShiftWorktime) + parseFloat(item.otNightShiftWorktime)
        item.totalWorkHour = item.totalWorkHour ? parseFloat(item.totalWorkHour).toFixed(2) : 0
        item.normalWorktime = parseFloat(item.normalWorktime).toFixed(2)
        item.nightShiftWorktime = item.nightShiftWorktime ? parseFloat(item.nightShiftWorktime).toFixed(2) : 0
        item.otWorktime = parseFloat(item.otWorktime).toFixed(2)
        item.otNightShiftWorktime = (this.isOtNightShift && item.otNightShiftWorktime) ? parseFloat(item.otNightShiftWorktime).toFixed(2) : 0
      },
      converNumber (str) {
        const timeStart = parseInt(str.slice(0, 2))
        const timeEnd = (parseInt(str.slice(3, 5)) / 60)
        return parseFloat(timeStart + timeEnd).toFixed(2)
      },
      convertTime (float) {
        let hour = parseInt(float)
        let minute = String(Math.round((float - parseInt(float)) * 60))

        if (hour.length === 1) {
          hour = '0' + hour
        }
        if (minute.length === 1) {
          minute = '0' + minute
        }

        return hour + ':' + minute
      },
      getValueObject (item, path, d = '') {
        return _.get(item, path, d)
      },
      onUpdate () {
        if (this.updateMode === 'ALL_ITEMS') {
          Object.keys(this.bulkModel).forEach(key => {
            this.items.forEach(item => {
              if (this.bulkModel[key] || this.bulkModel.breaktime === 0) item[key] = this.bulkModel[key]
              this.updateTimeSheetItem(item)
            })
          })
        } else if (this.updateMode === 'ONLY_BLANK_ITEMS') {
          this.items.forEach(item => {
            if (this.checkAllBlankProperties(item, Object.keys(this.bulkModel))) {
              Object.keys(this.bulkModel).forEach(key => {
                if (this.bulkModel[key] || this.bulkModel.breaktime === 0) item[key] = this.bulkModel[key]
                this.updateTimeSheetItem(item)
              })
            }
          })
        } else {
          if (!(this.selectedItems.length > 0)) {
            this.showSnackBar('error.none_items')
            return
          }
          Object.keys(this.bulkModel).forEach(key => {
            this.selectedItems.forEach(item => {
              if (this.bulkModel[key] || this.bulkModel.breaktime === 0) item[key] = this.bulkModel[key]
              this.updateTimeSheetItem(item)
            })
          })
        }
      },
      checkAllBlankProperties (obj, keyArray) {
        let isBlank = true
        keyArray.forEach(key => {
          if (obj[key] && obj[key] !== '00:00') {
            isBlank = false
          }
        })
        return isBlank
      },
      saveNote (item) {
        const payload = {
          value: event.target.value,
          item: item,
          prop: 'note',
        }
        this.save(payload)
      },
      saveFilter (payload) {
        if (payload.prop === 'actualStartAt') {
          const timeFirstStart = parseInt(payload.value.slice(0, 2))
          const timeLastStart = parseInt(payload.value.slice(3, 5))
          const timeFirstEnd = parseInt(payload.item.actualEndAt.slice(0, 2))
          const timeLastEnd = parseInt(payload.item.actualEndAt.slice(3, 5))
          if (timeFirstEnd === 0 && timeLastEnd === 0) {
            payload.item.actualEndAt = ''
          } else {
            if (this.checkActualAt(timeFirstStart, timeFirstEnd, timeLastStart, timeLastEnd)) {
              payload.item.actualStartAt = payload.item.actualEndAt
              this.updateTimeSheetItem(payload.item)
              return
            }
          }
        } else if (payload.prop === 'actualEndAt') {
          if (!payload.item.actualStartAt) {
            payload.item.actualStartAt = _.cloneDeep(payload.value)
            payload.item[payload.prop] = _.cloneDeep(payload.value)
          }
          const timeFirstStart = parseInt(payload.item.actualStartAt.slice(0, 2))
          const timeLastStart = parseInt(payload.item.actualStartAt.slice(3, 5))
          const timeFirstEnd = parseInt(payload.value.slice(0, 2))
          const timeLastEnd = parseInt(payload.value.slice(3, 5))
          if (this.checkActualAt(timeFirstStart, timeFirstEnd, timeLastStart, timeLastEnd)) {
            payload.item[payload.prop] = _.cloneDeep(payload.item.actualStartAt)
            this.updateTimeSheetItem(payload.item)
            return
          }
        }
        payload.item[payload.prop] = _.cloneDeep(payload.value)
        this.updateTimeSheetItem(payload.item)
      },
      save (payload) {
        if (payload.prop === 'actualStartAt') {
          const timeFirstStart = parseInt(payload.value.slice(0, 2))
          const timeLastStart = parseInt(payload.value.slice(3, 5))
          const timeFirstEnd = parseInt(payload.item.actualEndAt.slice(0, 2))
          const timeLastEnd = parseInt(payload.item.actualEndAt.slice(3, 5))
          if (timeFirstEnd === 0 && timeLastEnd === 0) {
            payload.item.actualEndAt = ''
          } else {
            if (this.checkActualAt(timeFirstStart, timeFirstEnd, timeLastStart, timeLastEnd)) {
              return
            }
          }
        } else if (payload.prop === 'actualEndAt') {
          const timeFirstStart = parseInt(payload.item.actualStartAt.slice(0, 2))
          const timeLastStart = parseInt(payload.item.actualStartAt.slice(3, 5))
          const timeFirstEnd = parseInt(payload.value.slice(0, 2))
          const timeLastEnd = parseInt(payload.value.slice(3, 5))
          if (this.checkActualAt(timeFirstStart, timeFirstEnd, timeLastStart, timeLastEnd)) {
            return
          }
        }
        payload.item[payload.prop] = _.cloneDeep(payload.value)
        this.updateTimeSheetItem(payload.item)
      },
      checkActualAt (timeFirstStart, timeFirstEnd, timeLastStart, timeLastEnd) {
        let error = false
        if (timeFirstStart > timeFirstEnd) {
          this.showSnackBar('出勤時間は退勤時間より前である必要があります。')
          error = true
        } else if (timeFirstStart === timeFirstEnd && timeLastStart > timeLastEnd) {
          this.showSnackBar('出勤時間は退勤時間より前である必要があります。')
          error = true
        }
        return error
      },
      checkBreakTime (items) {
        let isCheck = true

        items.forEach(item => {
          if (parseFloat(item.normalWorktime) < 0 || parseFloat(item.nightShiftWorktime) < 0) {
            this.showSnackBar(this.$t('error.breakTime'))
            isCheck = false
          }
        })

        return isCheck
      },
      checkActualTime (items) {
        let isCheck = true
        items.forEach(item => {
          if (!item.actualStartAt) {
            isCheck = false
          }

          if (!item.actualEndAt) {
            isCheck = false
          }
        })
        return isCheck
      },
      handleAttendenceRecord () {
        if (!this.checkActualTime(this.items)) {
          this.showSnackBar(this.$t('error.bad_input'))
          return
        }
        if (this.checkBreakTime(this.items)) {
          this.$store.dispatch(
            'timesheetEmployee/handleAttendenceRecord',
            this.convertTimeStamp(this.items),
          )
          // this.items = this.convertTimeString(this.items)
        }
      },
      getDate (string) {
        return moment(string).format('YYYY/MM/DD')
      },
      getTimePorid (startAt, endAt) {
        return (
          moment(startAt).format('HH:mm') + ' ~ ' + moment(endAt).format('HH:mm')
        )
      },
      getJob (item) {
        return _.get(item, 'shift.job.name', '')
      },
      fomatDateTime (string) {
        return moment(string).format('HH:mm')
      },
      getInitInfo () {
        this.$store.dispatch('timesheetEmployee/getParttimerEmployee', {
          id: this.parttimerManageId,
        })
        this.$store.dispatch('customer/getCustomer', {
          id: this.$route.query.customerId,
        })
      },
      getJobs (id) {
        this.$store.dispatch('timesheetEmployee/getJobs', id)
      },
      getTimeSheets (payload = {}) {
        const tmpOptions = { ...payload, ...this.options }
        tmpOptions.getBy = 'parttime'

        tmpOptions.customerId = this.$route.query.customerId
        tmpOptions.parttimerManageId = this.$route.query.parttimerManageId

        delete tmpOptions.page
        delete tmpOptions.groupDesc
        delete tmpOptions.groupBy
        delete tmpOptions.mustSort
        delete tmpOptions.multiSort
        delete tmpOptions.itemsPerPage
        delete tmpOptions.sortDesc
        delete tmpOptions.sortBy
        delete tmpOptions.isTrusted
        if (this.dateStart) {
          this.$store.dispatch('timesheetEmployee/getTimeSheets', tmpOptions)
        } else {
          this.items = []
        }
      },
      getParttimerEmployee () {
        this.resetData()
        if (this.parttimerManageId && this.customer) {
          this.$store.dispatch('timesheetEmployee/getParttimerEmployee', {
            keyword: this.parttimerManageId,
          })
          this.getJobs(this.customer)
          this.getTimeSheets()
        }
      },
      resetData () {
        this.items = []
        this.job = ''
        this.dateStart = ''
        this.dateEnd = ''
        this.name = ''
        this.controlNumber = ''
        this.sex = null
      },
      convertTimeStringUTC (items) {
        this.items.forEach((item) => {
          if (
            moment.utc(new Date(item.plannedStartAt)).format('YYYY-MM-DD') ===
            moment.utc(new Date(item.actualEndAt)).format('YYYY-MM-DD')
          ) {
            item.actualEndAt = moment
              .utc(new Date(item.actualEndAt))
              .format('HH:mm')
          } else if (
            moment.utc(new Date(item.plannedStartAt)).add(2, 'days').format('YYYY-MM-DD') ===
            moment.utc(new Date(item.actualEndAt)).format('YYYY-MM-DD')
          ) {
            item.actualEndAt = '48:00'
          } else {
            item.actualEndAt = this.handleCalculatorTime(moment.utc(new Date(item.actualEndAt)).format('HH:mm'))
          }
          if (
            moment.utc(new Date(item.plannedStartAt)).format('YYYY-MM-DD') ===
            moment.utc(new Date(item.actualStartAt)).format('YYYY-MM-DD')
          ) {
            item.actualStartAt = moment
              .utc(new Date(item.actualStartAt))
              .format('HH:mm')
          } else if (
            moment.utc(new Date(item.plannedStartAt)).add(2, 'days').format('YYYY-MM-DD') ===
            moment.utc(new Date(item.actualEndAt)).format('YYYY-MM-DD')
          ) {
            item.actualEndAt = '48:00'
          } else {
            item.actualStartAt = this.handleCalculatorTime(moment.utc(new Date(item.actualStartAt)).format('HH:mm'))
          }
        })
        return _.cloneDeep(items)
      },
      handleCheckTime (time) {
        let setTime = String(parseInt(time.slice(0, 2)) - 24)
        if (setTime.length === 1) {
          setTime = '0' + setTime + ':'
        } else if (setTime.length === 2) {
          setTime += ':'
        }
        setTime += String(time.slice(3, 5))
        return setTime
      },
      handleCalculatorTime (time) {
        let setTime = String(parseInt(time.slice(0, 2)) + 24)
        setTime += ':' + String(time.slice(3, 5))
        return setTime
      },
      convertTimeStamp (items) {
        this.items.forEach((item) => {
          if (item.isPresent || item.isPresent === null) {
            let startAt = _.cloneDeep(item.actualStartAt)
            let endAt = _.cloneDeep(item.actualEndAt)
            let plannedStartAt = item.plannedStartAt
            let plannedEndAt = item.plannedStartAt

            if (parseInt(startAt.slice(0, 2)) >= 24) {
              startAt = this.handleCheckTime(startAt)
              plannedStartAt = moment.utc(new Date(item.plannedStartAt)).add(1, 'days').format('YYYY-MM-DD')
            }

            if (parseInt(endAt.slice(0, 2)) >= 24) {
              endAt = this.handleCheckTime(endAt)
              plannedEndAt = moment.utc(new Date(item.plannedStartAt)).add(1, 'days').format('YYYY-MM-DD')
            }

            const endTime =
              moment.utc(new Date(plannedEndAt)).format('YYYY-MM-DD') +
              'T' + endAt

            const startTime =
              moment.utc(new Date(plannedStartAt)).format('YYYY-MM-DD') +
              'T' + startAt

            item.actualStartAt = startTime

            if (moment.utc(startTime).unix() > moment.utc(endTime).unix()) {
              item.actualEndAt =
                moment
                  .utc(new Date(plannedEndAt))
                  .add(1, 'day')
                  .format('YYYY-MM-DD') +
                'T' +
                endAt
            } else {
              item.actualEndAt = endTime
            }
          } else {
            item.actualEndAt =
              moment.utc(new Date(item.plannedStartAt)).format('YYYY-MM-DD') +
              'T00:00:00'
            item.actualStartAt =
              moment.utc(new Date(item.plannedEndAt)).format('YYYY-MM-DD') +
              'T00:00:00'
            item.normalWorktime = 0
            item.nightShiftWorktime = 0
            item.otWorktime = 0
            item.otNightShiftWorktime = 0
            item.breaktime = 0
            item.totalWorkHour = 0
          }
        })
        return _.cloneDeep(items)
      },
      convertTimeString (items) {
        this.items.forEach(item => {
          item.actualEndAt = moment(new Date(item.actualEndAt)).format('HH:mm')
          item.actualStartAt = moment(new Date(item.actualStartAt)).format(
            'HH:mm',
          )
        })
        return _.cloneDeep(items)
      },
      itemRowBackground (item) {
        if (item.isPresent === false) {
          return 'disableShift'
        }
        return ''
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
    },
  }
</script>

<style scoped lang="sass">
.border-right
  border-right: thin solid rgba(0, 0, 0, 0.12)!important

.shiftWarp
  margin-top: 15px
  padding: 0 50px

  .infoWarp
    padding-top: 20px

  .lblText
    margin-top: 10px

.dateRadio
  display: flex
  align-items: center

  h3
    margin-right: 20px
.dateWarp
  display: flex
  justify-content: space-between

  .icon-fall
    font-size: 20px
    font-weight: bold
    margin-top: 10px
.btnAddHour
  width: 41px
  min-width: 41px!important
.shiftInformation, .department
  color: #4262FF
  font-weight: 600
.attendancConfirm
  color: #E43E08
.highlights
  color: #E43E08
.departmentText
  height: 66px
  line-height: 66px
.shiftAt
  margin-left: 0px!important
.editShiftAt
  padding: 0px!important
.attendanceConfirmation
  cursor: pointer
.shiftInformation
  cursor: pointer
.errorBreakTime
  color: #E43E08
</style>
